import React, {useContext} from 'react';
import Context from '../../context/ContextProvider';
import btnReiniciar from '../../images/btn_reiniciar.png';
import btnDescargar from '../../images/btn_descargar.png';
import * as svg from 'save-svg-as-png';

import './Botones.scss'

const DibujoPrueba = () => {

    const stateColor = useContext(Context);
    const { colorObtenido } = stateColor;

    const obtenetID = (e) =>{
        e.target.style.fill = colorObtenido;        
    }

    const reiniciar = () => {
        window.location.reload();
    }

    const download = () => {
        svg.saveSvgAsPng(document.getElementById('Capa_1'), 'artesania.png');
    };

    
    return (
        <div className='div-dibujo'>
           <svg version="1.1" id="Capa_1" x="0px" y="0px"
                width="845px" height="659px" viewBox="0 0 845 659"
                onClick={obtenetID}
            >
            <g>
                <g>
                    <path d="M461.18,123.86c-2.74-0.22-5.23-2.13-6.16-4.7c-0.97-2.59-0.31-5.52,1.26-7.65c1.56-2.18,4.15-3.57,6.83-3.67
                        c2.67-0.08,5.3,0.88,7.33,2.59c2.03,1.7,3.41,4.16,3.78,6.79c0.39,2.64-0.2,5.31-1.37,7.65c-1.2,2.34-3.08,4.31-5.36,5.62
                        c-2.28,1.31-4.93,1.95-7.56,1.79c-5.23-0.22-10.24-3.11-13.17-7.45c-1.46-2.18-2.43-4.69-2.79-7.29
                        c-0.08-0.65-0.11-1.31-0.15-1.97c0.03-0.66,0.01-1.32,0.08-1.97l0.26-1.94l0.45-1.91c1.42-5.04,4.78-9.52,9.26-12.27
                        c4.45-2.78,10-3.82,15.15-2.84c2.56,0.49,5.1,1.24,7.39,2.5c2.3,1.24,4.41,2.82,6.22,4.68c1.79,1.89,3.3,4.01,4.53,6.28
                        c1.19,2.3,2.06,4.73,2.69,7.22c0.58,2.51,0.85,5.07,0.87,7.63c-0.03,2.58-0.46,5.16-1.32,7.6c-0.84,2.45-2.19,4.68-3.71,6.74
                        c-1.52,2.07-3.31,3.91-5.2,5.61c-3.77,3.43-8,6.26-12.26,8.98c-4.27,2.7-8.63,5.23-12.91,7.87c-4.28,2.64-8.5,5.39-12.36,8.57
                        c-1.91,1.61-3.76,3.29-5.4,5.16c-1.63,1.87-3.07,3.9-4.21,6.08l-0.01,0.01c-0.14,0.27-0.48,0.38-0.75,0.24s-0.38-0.48-0.24-0.75
                        l0,0c1.2-2.28,2.72-4.37,4.41-6.29c1.69-1.92,3.6-3.62,5.54-5.25c3.95-3.2,8.19-5.98,12.48-8.64c4.29-2.66,8.64-5.2,12.89-7.89
                        c4.22-2.71,8.41-5.52,12.09-8.89c1.85-1.67,3.59-3.47,5.05-5.47c1.46-1.99,2.75-4.13,3.54-6.46c0.81-2.32,1.21-4.76,1.24-7.23
                        c-0.06-2.48-0.35-4.94-0.95-7.34c-0.63-2.39-1.53-4.7-2.72-6.85c-1.23-2.13-2.72-4.09-4.44-5.83c-1.75-1.71-3.76-3.11-5.91-4.24
                        c-2.14-1.15-4.51-1.82-6.89-2.25c-4.78-0.87-9.79,0.01-13.91,2.54c-4.12,2.5-7.31,6.54-8.7,11.19l-0.44,1.76l-0.27,1.8
                        c-0.08,0.6-0.07,1.2-0.1,1.8c0.03,0.6,0.05,1.2,0.12,1.8c0.3,2.39,1.15,4.71,2.47,6.74c2.64,4.04,7.24,6.81,12.12,7.08
                        c2.41,0.15,4.85-0.44,6.95-1.63c2.1-1.2,3.83-3.01,4.94-5.16c1.08-2.15,1.62-4.63,1.27-7c-0.33-2.37-1.57-4.6-3.41-6.14
                        c-1.82-1.55-4.23-2.42-6.6-2.36c-2.36,0.09-4.66,1.3-6.04,3.24c-1.38,1.9-1.93,4.5-1.09,6.67c0.81,2.19,2.93,3.79,5.25,3.97
                        c0.31,0.02,0.54,0.29,0.52,0.6c-0.02,0.31-0.29,0.54-0.6,0.52l0,0L461.18,123.86z"/>
                </g>
                <g>
                    <path d="M386.25,122.75c2.32-0.17,4.45-1.78,5.25-3.97c0.84-2.17,0.3-4.77-1.09-6.67c-1.38-1.93-3.68-3.15-6.04-3.24
                        c-2.37-0.07-4.78,0.81-6.6,2.36c-1.84,1.54-3.08,3.77-3.41,6.14c-0.35,2.36,0.19,4.85,1.27,7c1.11,2.15,2.85,3.96,4.94,5.16
                        c2.1,1.2,4.53,1.78,6.95,1.63c4.88-0.26,9.48-3.03,12.12-7.08c1.32-2.03,2.17-4.35,2.47-6.74c0.07-0.6,0.08-1.2,0.12-1.8
                        c-0.04-0.6-0.03-1.2-0.1-1.8l-0.27-1.8l-0.44-1.76c-1.39-4.66-4.58-8.7-8.7-11.19c-4.12-2.54-9.13-3.42-13.91-2.54
                        c-2.38,0.43-4.74,1.1-6.89,2.25c-2.14,1.13-4.16,2.53-5.91,4.24c-1.72,1.73-3.21,3.7-4.44,5.83c-1.19,2.15-2.09,4.46-2.72,6.85
                        c-0.59,2.4-0.88,4.87-0.95,7.34c0.03,2.46,0.43,4.9,1.24,7.23c0.79,2.33,2.08,4.47,3.54,6.46c1.46,2,3.2,3.8,5.05,5.47
                        c3.69,3.37,7.87,6.18,12.09,8.89c4.25,2.69,8.6,5.23,12.89,7.89c4.29,2.66,8.53,5.44,12.48,8.64c1.95,1.63,3.85,3.33,5.54,5.25
                        c1.69,1.92,3.2,4.01,4.41,6.29c0.14,0.27,0.04,0.61-0.23,0.76c-0.27,0.14-0.61,0.04-0.76-0.23l0,0l-0.01-0.01
                        c-1.14-2.19-2.59-4.21-4.21-6.08c-1.63-1.88-3.49-3.55-5.4-5.16c-3.86-3.18-8.08-5.94-12.36-8.57c-4.28-2.64-8.64-5.18-12.91-7.87
                        c-4.25-2.72-8.48-5.55-12.26-8.98c-1.89-1.7-3.68-3.54-5.2-5.61c-1.52-2.06-2.87-4.29-3.71-6.74c-0.86-2.44-1.28-5.02-1.32-7.6
                        c0.03-2.56,0.29-5.13,0.87-7.63c0.64-2.49,1.5-4.93,2.69-7.22c1.23-2.27,2.74-4.4,4.53-6.28c1.82-1.86,3.92-3.44,6.22-4.68
                        c2.3-1.26,4.84-2.01,7.39-2.5c5.14-0.98,10.69,0.06,15.15,2.84c4.48,2.75,7.84,7.23,9.26,12.27l0.45,1.91l0.26,1.94
                        c0.07,0.65,0.05,1.31,0.08,1.97c-0.04,0.66-0.07,1.32-0.15,1.97c-0.36,2.61-1.33,5.12-2.79,7.29c-2.93,4.35-7.94,7.24-13.17,7.45
                        c-2.62,0.16-5.28-0.49-7.56-1.79c-2.28-1.31-4.16-3.28-5.36-5.62c-1.17-2.34-1.76-5.01-1.37-7.65c0.37-2.63,1.75-5.09,3.78-6.79
                        c2.02-1.71,4.66-2.67,7.33-2.59c2.68,0.1,5.27,1.49,6.83,3.67c1.57,2.14,2.23,5.06,1.26,7.65c-0.93,2.57-3.42,4.48-6.16,4.7
                        l-0.01,0c-0.31,0.02-0.58-0.21-0.6-0.51C385.71,123.04,385.94,122.77,386.25,122.75L386.25,122.75z"/>
                </g>
            </g>
            <path fill="#FFFFFF" stroke="#000000" d="M439.58,301.19
                c0,0,140.13-6.01,241.59,91.22c78.19,74.93,82.91,127.52,57.34,147.26c-21.17,16.34-58.64-4.56-58.64-4.56s17.59,39.09-10.43,59.29
                s-68.42-3.26-68.42-3.26s17.63,44.48-25.41,57.34C512.6,667.31,439.58,509.04,439.58,431.5V301.19z"/>
            <path fill="#FFFFFF" stroke="#000000" d="M439.5,301.19v130.33c0,14.56,2.59,32.02,7.2,50.6
                C479.59,343,622.52,348.69,622.52,348.69C533,297.17,439.5,301.19,439.5,301.19z"/>
            <path fill="#FFFFFF" stroke="#000000" d="M461.52,348.98
                c0,0-5.53,233.67,94.36,244.91c21.26,2.39,20.27-35.07,18.63-58.08c0,0,25.75,35.07,53.69,16.99c27.94-18.08,6.03-58.63,6.03-58.63
                s41.7,34.02,59.17,0C721.34,439.79,589.73,343.5,461.52,348.98z"/>
            <path fill="#FFFFFF" stroke="#000000" d="M439.58,242.74
                c0,0,20.63-90.27,155.62-175.39s219.35-69.18,239.01-4.3c17.2,56.74-50.73,77.38-50.73,77.38s37.83,25.79,23.21,61.9
                c-14.62,36.11-55.88,27.51-55.88,27.51s26.09,42.13-16.61,74.8s-210.36-49.87-294.62,91.99V242.74z"/>
            <g>
                <g>
                    <g>
                        <path fill="#FFFFFF" d="M407.93,301.19v130.33c0,14.56-2.59,32.02-7.2,50.6c-19.92,80.19-77.64,181.64-128.82,166.36
                            c-42.48-12.68-25.91-56.08-25.45-57.3c-1.72,0.96-41.02,22.94-68.39,3.22c-28-20.21-10.42-59.3-10.42-59.3
                            s-37.46,20.88-58.64,4.56c-25.57-19.75-20.84-72.32,57.34-147.28c18.42-17.66,38.13-31.89,58.05-43.4
                            c0.17-0.13,0.33-0.21,0.5-0.29C314.44,297.17,407.93,301.19,407.93,301.19z"/>
                    </g>
                </g>
            </g>
            <path fill="#FFFFFF" stroke="#000000" d="M407.93,301.19v130.33c0,14.56-2.59,32.02-7.2,50.6
                C367.84,343,224.91,348.69,224.91,348.69C314.44,297.17,407.93,301.19,407.93,301.19z"/>
            <path fill="none" stroke="#000000" d="M407.94,301.19
                c0,0-140.13-6.01-241.59,91.22c-78.19,74.93-82.91,127.52-57.34,147.26c21.17,16.34,58.64-4.56,58.64-4.56s-17.59,39.09,10.43,59.29
                s68.42-3.26,68.42-3.26s-17.63,44.48,25.41,57.34c63.02,18.83,136.03-139.44,136.03-216.97V301.19z"/>
            <path fill="#FFFFFF" d="M371.52,489.34c-12.6,51.98-36.12,99.61-79.9,104.55c-21.26,2.39-20.26-35.07-18.62-58.09
                c0,0-25.74,35.07-53.7,16.99c-27.37-17.75-6.86-57.09-6.07-58.59c-1.67,1.34-42.02,33.27-59.1-0.04
                c-18.12-35.28,30.89-88.18,102.58-119.49c38.8-16.99,84.25-27.62,129.28-25.7C386,348.97,387.76,422.71,371.52,489.34z"/>
            <g>
                <g>
                    <g>
                        <path fill="#FFFFFF" d="M371.52,489.34c-0.04,0-0.04,0-0.08,0c-63.36,0-114.68-51.31-114.72-114.68
                            c38.8-16.99,84.25-27.62,129.28-25.7C386,348.97,387.76,422.71,371.52,489.34z"/>
                    </g>
                </g>
            </g>
            <circle fill="#FFFFFF" cx="292.04" cy="454.03" r="48.5"/>
            <circle fill="none" stroke="#000000" cx="292.04" cy="454.03" r="48.5"/>
            <g>
                <circle fill="#FFFFFF" cx="292.04" cy="454.03" r="29.04"/>
            </g>
            <g>
                
                    <circle fill="none" stroke="#000000" cx="292.04" cy="454.03" r="29.04"/>
            </g>
            <path fill="none" stroke="#000000" d="M386.01,348.98
                c0,0,5.53,233.67-94.36,244.91c-21.26,2.39-20.27-35.07-18.63-58.08c0,0-25.75,35.07-53.69,16.99
                c-27.94-18.08-6.03-58.63-6.03-58.63s-41.7,34.02-59.17,0C126.19,439.79,257.8,343.5,386.01,348.98z"/>
            <path fill="none" stroke="#000000" d="M263.88,414.55
                c-4.65-12.39-7.16-25.86-7.16-39.89"/>
            <path fill="none" stroke="#000000" d="M371.44,489.34
                c-14.06,0-27.5-2.51-39.93-7.16"/>
            <g>
                <g>
                    <g>
                        <path fill="#FFFFFF" d="M407.93,242.72v153.89c-48.3-81.32-124.01-88.89-187.87-86.68h-0.04c-47.5,1.59-88.48,8.62-106.68-5.32
                            c-42.73-32.64-16.62-74.79-16.62-74.79s-41.27,8.62-55.87-27.5c-14.65-36.12,23.19-61.9,23.19-61.9S-3.89,119.8,13.31,63.05
                            c19.67-64.87,104.04-80.82,239.02,4.31c1.17,0.71,2.3,1.46,3.43,2.18c0,0,0.04,0.04,0.08,0.08
                            C387.68,154.04,407.93,242.72,407.93,242.72z"/>
                    </g>
                </g>
            </g>
            <path fill="#FFFFFF" stroke="#000000" d="M407.93,242.72v153.89c-48.3-81.32-124.01-88.89-187.87-86.68
                c4.45-3,8.97-5.6,13.54-7.84c79.15-38.87,98.48-143.37,41.63-210.77c-10.98-13.02-18.51-20.8-19.39-21.71
                C387.68,154.04,407.93,242.72,407.93,242.72z"/>
            <path fill="#FFFFFF" stroke="#000000" d="M438.5,243.72v153.89c48.3-81.32,124.01-88.89,187.87-86.68
                c-4.45-3-8.97-5.6-13.54-7.84c-79.15-38.87-98.48-143.37-41.63-210.77c10.98-13.02,18.51-20.8,19.39-21.71
                C458.76,155.04,438.5,243.72,438.5,243.72z"/>
            <path fill="#FFFFFF" d="M381.4,315.07c-53.32-47.13-118.19-50.35-168.79-51.19c-39.84-0.67-70.9,0.17-80.48-17.75
                c-17.24-32.27,24.78-43.4,24.78-43.4s-43.53-1.72-52.02-28.08c-8.5-26.37,20.42-45.95,20.42-45.95s-68.05-1.67-55.29-52.73
                s103.54-20.13,181.51,34.03c10.34,7.16,19.88,14.82,28.67,22.73C364.95,209.39,381.4,315.07,381.4,315.07z"/>
            <path fill="#FFFFFF" d="M381.4,315.07c-53.32-47.13-118.19-50.35-168.79-51.19c-5.02-11.59-7.78-24.36-7.78-37.75
                c0-45.83,32.31-84.12,75.38-93.41C364.95,209.39,381.4,315.07,381.4,315.07z"/>
            <circle fill="#FFFFFF" cx="222.57" cy="179.31" r="51.51"/>
            <circle fill="none" stroke="#000000" cx="222.57" cy="179.31" r="51.51"/>
            <circle fill="#FFFFFF" cx="222.57" cy="179.31" r="33.2"/>
            <circle fill="none" stroke="#000000" cx="222.57" cy="179.31" r="33.2"/>
            <g>
                <path fill="none" stroke="#000000" d="M407.94,242.74
                    c0,0-20.63-90.27-155.62-175.39S32.98-1.83,13.32,63.05c-17.2,56.74,50.73,77.38,50.73,77.38s-37.83,25.79-23.21,61.9
                    s55.88,27.51,55.88,27.51s-26.09,42.13,16.61,74.8s210.36-49.87,294.62,91.99V242.74z"/>
                <path fill="none" stroke="#000000" d="M381.42,315.05
                    c0,0-20.06-128.74-129.88-205.04C173.55,55.83,82.76,24.91,70,75.96s55.31,52.75,55.31,52.75s-28.93,19.57-20.42,45.95
                    s52,28.09,52,28.09s-42.02,11.11-24.77,43.38C153.88,286.85,286.12,230.82,381.42,315.05z"/>
                <g>
                    <path fill="none" stroke="#000000" d="M212.61,263.88
                        c-4.81-11.13-7.58-23.35-7.74-36.2"/>
                    <path fill="none" stroke="#000000" d="M256.97,141.01
                        c7.28-3.72,15.07-6.53,23.23-8.29"/>
                </g>
            </g>
            <g>
                <circle fill="#FFFFFF" cx="555.49" cy="454.03" r="29.04"/>
            </g>
            <circle fill="#FFFFFF" stroke="#000000" cx="555.49" cy="454.03" r="48.5"/>
            <g>
                
                    <circle fill="#FFFFFF" stroke="#000000" cx="555.49" cy="454.03" r="29.04"/>
            </g>
            <path fill="none" stroke="#000000" d="M590.8,374.67
                c0,14.02-2.51,27.5-7.16,39.89"/>
            <path fill="none" stroke="#000000" d="M516.01,482.19
                c-12.43,4.65-25.86,7.16-39.93,7.16"/>
            <circle fill="#FFFFFF" cx="300.4" cy="226.15" r="28.41"/>
            <circle fill="#FFFFFF" cx="300.4" cy="226.15" r="14.86"/>
            <circle fill="#FFFFFF" cx="547.12" cy="226.15" r="14.86"/>
            <g>
                
                    <circle fill="none" stroke="#000000" cx="300.4" cy="226.15" r="28.41"/>
                
                    <circle fill="none" stroke="#000000" cx="300.4" cy="226.15" r="14.86"/>
            </g>
            <g>
                
                    <circle fill="none" stroke="#000000" cx="300.4" cy="226.15" r="28.41"/>
                
                    <circle fill="none" stroke="#000000" cx="300.4" cy="226.15" r="14.86"/>
            </g>
            <g>
                
                    <circle fill="none" stroke="#000000" cx="300.4" cy="226.15" r="28.41"/>
                
                    <circle fill="none" stroke="#000000" cx="300.4" cy="226.15" r="14.86"/>
            </g>
            <path fill="#FFFFFF" d="M415.29,200.58c0,5.86-4.06,10.81-9.51,12.21c-2.03-3.24-3.15-7.03-3.15-11.09c0-4.1,1.17-8.88,3.2-13.38
                C411.28,189.72,415.29,194.63,415.29,200.58z"/>
            <path fill="#FFFFFF" d="M458.11,411.31c0,19-15.4,34.36-34.36,34.36c-18.96,0-34.36-15.36-34.36-34.36c0-3.26,0.8-10.38,2.09-19.96
                c3.01-22.18,8.91-57.55,14.48-88.48v-0.04l35.57,0.04c5.57,30.93,11.47,66.29,14.48,88.48
                C457.32,400.93,458.11,408.04,458.11,411.31z"/>
            <path fill="#FFFFFF" d="M458.11,411.31c0,19-15.4,34.36-34.36,34.36c-18.96,0-34.36-15.36-34.36-34.36c0-3.26,0.8-10.38,2.09-19.96
                c4.77,13.14,17.45,22.56,32.27,22.56c14.82,0,27.46-9.42,32.23-22.56h0.04C457.32,400.93,458.11,408.04,458.11,411.31z"/>
            <path fill="none" stroke="#000000" d="M391.5,391.32c4.78,13.16,17.44,22.58,32.26,22.58
                c14.83,0,27.44-9.42,32.22-22.58"/>
            <path fill="#FFFFFF" d="M444.87,201.69c0,4.07-1.17,7.87-3.17,11.08c-5.48-1.37-9.48-6.34-9.48-12.22c0-5.87,3.99-10.81,9.44-12.22
                C443.7,192.84,444.87,197.62,444.87,201.69z"/>
            <path fill="#FFFFFF" stroke="#000000" d="M444.87,201.69c0,4.07-1.17,7.87-3.17,11.08
                c-3.68,6.03-10.38,10.06-17.93,10.06c-7.6,0-14.25-4.03-17.97-10.06c-2.04-3.21-3.17-7.01-3.17-11.08c0-4.07,1.17-8.85,3.21-13.35
                c3.72-8.3,10.38-15.58,17.93-15.58c7.56,0,14.17,7.28,17.89,15.58C443.7,192.84,444.87,197.62,444.87,201.69z"/>
            <path fill="#FFFFFF" stroke="#000000" d="M452.62,263.05c0-22.22-12.92-40.23-28.87-40.23
                s-28.87,18.01-28.87,40.23s12.92,53.41,28.87,53.41S452.62,285.27,452.62,263.05z"/>
            <path fill="none" stroke="#000000" d="M441.55,302.87c7.99,44.33,16.56,97.7,16.56,108.42
                c0,18.99-15.39,34.38-34.34,34.38c-18.99,0-34.38-15.39-34.38-34.38c0-10.73,8.58-64.1,16.56-108.42v-0.04"/>
            <path fill="#FFFFFF" stroke="#000000" d="M444.87,201.69c0,4.07-1.17,7.87-3.17,11.08
                c-5.48-1.37-9.48-6.34-9.48-12.22c0-5.87,3.99-10.81,9.44-12.22C443.7,192.84,444.87,197.62,444.87,201.69z"/>
            <path fill="#FFFFFF" stroke="#000000" d="M402.62,201.69c0,4.07,1.17,7.87,3.17,11.08
                c5.48-1.37,9.48-6.34,9.48-12.22c0-5.87-3.99-10.81-9.44-12.22C403.8,192.84,402.62,197.62,402.62,201.69z"/>
            <path fill="#FFFFFF" stroke="#000000" d="M466.11,315.05
                c0,0,20.06-128.74,129.88-205.04c77.99-54.18,168.77-85.11,181.54-34.06c12.76,51.05-55.31,52.75-55.31,52.75
                s28.93,19.57,20.42,45.95c-8.51,26.38-52,28.09-52,28.09s42.02,11.11,24.77,43.38C693.65,286.85,561.41,230.82,466.11,315.05z"/>
            <g>
                <path fill="none" stroke="#000000" d="M642.66,227.68
                    c-0.17,12.85-2.93,25.07-7.74,36.2"/>
                <path fill="none" stroke="#000000" d="M567.33,132.72
                    c8.16,1.76,15.95,4.56,23.23,8.29"/>
            </g>
            <circle fill="#FFFFFF" stroke="#000000" cx="624.95" cy="179.31" r="51.51"/>
            <circle fill="#FFFFFF" stroke="#000000" cx="624.95" cy="179.31" r="33.2"/>
            <circle fill="#FFFFFF" stroke="#000000" cx="547.12" cy="226.15" r="28.41"/>
            <circle fill="#FFFFFF" stroke="#000000" cx="547.12" cy="226.15" r="14.86"/>
            </svg>



            <div className='botones'>
                <div className='btn'>
                    <img src={btnReiniciar} alt="imagen" onClick={reiniciar}/>
                </div>

                <div className='btn'>
                    <img src={btnDescargar} alt="imagen" onClick={download} />
                </div>
            </div>
        </div>
    )
}

export default DibujoPrueba